import { useDisclosure } from "@chakra-ui/hooks";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Collapse,
    FormControl,
    FormErrorMessage,
    Input,
    InputGroup,
    Select,
    Text,
    useToast,
    VStack
} from '@chakra-ui/react';
import { parseCookies } from "nookies";
import { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { usePetition } from "../../hooks/usePetition";
import { useSignature } from "../../hooks/useSignature";
import wrongEmailDomains from "../../lib/wrongEmailDomains.json";

function isInvalidEmailDomain(setError, email, setCorrectDomain, setWrongDomain) {
    const emailSplitAtAtSign = email.split('@')
    const emailSplitAtDot = emailSplitAtAtSign[1].split('.')

    const emailWrong = wrongEmailDomains.find(domain => domain.wrong === emailSplitAtDot[0])
    if (emailWrong === undefined) {
        return false
    }

    setWrongDomain(emailSplitAtDot[0])
    setCorrectDomain(emailWrong.correct)
    setError('email', { type: "wrongDomain" }, { shouldFocus: true })
    return true
}

export default function Form() {
    const { petitionData, addSubscription } = usePetition()
    const reRef = useRef()
    const cookies = parseCookies()
    let cookieSubscriberData = {}
    if (cookies['@campaign_subscriberData'] !== undefined) {
        cookieSubscriberData = JSON.parse(cookies['@campaign_subscriberData'])
    }
    const { setIsSigned } = useSignature()
    const [apiReturnMessage, setApiReturnMessage] = useState('')
    const uf = [{ "sigla": "AC" }, { "sigla": "AL" }, { "sigla": "AM" }, { "sigla": "AP" }, { "sigla": "BA" }, { "sigla": "CE" }, { "sigla": "DF" }, { "sigla": "ES" }, { "sigla": "GO" }, { "sigla": "MA" }, { "sigla": "MG" }, { "sigla": "MS" }, { "sigla": "MT" }, { "sigla": "PA" }, { "sigla": "PB" }, { "sigla": "PE" }, { "sigla": "PI" }, { "sigla": "PR" }, { "sigla": "RJ" }, { "sigla": "RN" }, { "sigla": "RO" }, { "sigla": "RR" }, { "sigla": "RS" }, { "sigla": "SC" }, { "sigla": "SE" }, { "sigla": "SP" }, { "sigla": "TO" },]

    const [email, setEmail] = useState(cookieSubscriberData?.email ?? '')
    const [firstName, setFirstName] = useState(cookieSubscriberData?.first_name ?? '')
    const [lastName, setLastName] = useState(cookieSubscriberData?.last_name ?? '')
    const [state, setState] = useState(cookieSubscriberData?.state ?? '')
    const [city, setCity] = useState(cookieSubscriberData?.city ?? '')
    const [country, setCountry] = useState(cookieSubscriberData?.country ?? 'Brasil')

    const [correctDomain, setCorrectDomain] = useState('')
    const [wrongDomain, setWrongDomain] = useState('')
    const { handleSubmit, register, setError, clearErrors, formState: { errors, isSubmitting } } = useForm()
    const { isOpen, onToggle } = useDisclosure()
    const toast = useToast()


    const toogleErroMessage = () => {
        if (isOpen) {
            onToggle()
            setApiReturnMessage('')
        }
    }
    const [countries, setCountries] = useState([])

    async function getCountries() {
        const countriesRes = await fetch('https://raw.githubusercontent.com/institutopco/utils/main/v1/countries', {
            method: 'GET'
        })
        const countriesData = await countriesRes.json()
        const countriesSorted = countriesData.sort(function (a, b) {
            const nameA = a.names.portuguese.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            const nameB = b.names.portuguese.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

            if (nameA < nameB) {
                return -1
            }
            if (nameA > nameB) {
                return +1
            }
            return 0
        })
        setCountries(countriesSorted)
    }

    useEffect(() => {
        getCountries()
    }, [])

    async function onSubmit() {
        if (isInvalidEmailDomain(setError, email, setCorrectDomain, setWrongDomain)) {
            return
        }

        const token = await reRef.current.executeAsync()
        reRef.current.reset()

        const endpoint = "/api/signatures"

        const res = await fetch(endpoint, {
            headers: {
                "Accept": "application/json", "Content-Type": "application/json"
            }, method: "POST", body: JSON.stringify({
                "petition_id": petitionData.id,
                "email": email,
                "first_name": firstName,
                "last_name": lastName,
                "city": city,
                "state": state,
                "country": country,
                token
            })
        })
        if (res.ok) {
            addSubscription()
            setIsSigned(true)
            return
        }

        if (res.status === 409) {
            setIsSigned(true)
            toast({
                title: "Petição já assinada anteriormente",
                description: "Compartilhe com seus amigos para que eles possam assinar também",
                status: "info",
                variant: "left-accent",
                position: "top-right",
                duration: 9000,
                isClosable: true,
            })
            return
        }
        const signature = await res.json()
        setApiReturnMessage(signature.error)
        onToggle()
    }

    return (<Box>
        <form onSubmit={handleSubmit(onSubmit)}>
            <InputGroup mt="1rem">
                <FormControl isInvalid={errors.firstName} autoComplete="off" mr="0.5rem">
                    <Input
                        name="firstName"
                        variant="filled"
                        colorScheme="gray"
                        placeholder="Digite seu nome"
                        borderRadius="50"
                        borderColor="black"
                        onFocus={toogleErroMessage}
                        value={firstName}
                        {...register("firstName", {
                            required: true, minLength: 2, maxLength: 30, onChange: (e) => {
                                setFirstName(e.target.value)
                            }
                        })}
                    />
                    <FormErrorMessage pl="1rem">
                        {errors.firstName?.type === 'required' && "Esse campo é obrigatório"}
                        {errors.firstName?.type === "minLength" && "Digite seu primeiro nome"}
                        {errors.firstName?.type === "maxLength" && "Digite apenas o seu primeiro nome"}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.lastName} autoComplete="off">
                    <Input
                        name="lastName"
                        variant="filled"
                        colorScheme="gray"
                        placeholder="Digite seu sobrenome"
                        borderRadius="50"
                        borderColor="black"
                        onFocus={toogleErroMessage}
                        value={lastName}
                        {...register("lastName", {
                            required: true, minLength: 2, maxLength: 30, onChange: (e) => {
                                setLastName(e.target.value)
                            }
                        })}
                    />
                    <FormErrorMessage pl="1rem">
                        {errors.lastName?.type === "required" && "Esse campo é obrigatório"}
                        {errors.lastName?.type === "minLength" && "Digite o seu último nome"}
                        {errors.lastName?.type === "maxLength" && "Digite apenas o seu último nome"}
                    </FormErrorMessage>
                </FormControl>
            </InputGroup>
            <FormControl isInvalid={errors.email} autoComplete="off">
                <Input
                    name="email"
                    variant="filled"
                    colorScheme="gray"
                    placeholder="Informe seu e-mail"
                    borderRadius="50"
                    borderColor="black"
                    mt="1rem"
                    w="full"
                    onFocus={toogleErroMessage}
                    value={email}
                    {...register("email", {
                        required: true, pattern: /^\S+@\S+$/i, onChange: (e) => {
                            setEmail(e.target.value)
                        }
                    })}
                />
                <FormErrorMessage pl="1rem">
                    {errors.email?.type === "required" && "Esse campo é obrigatório"}
                    {errors.email?.type === "pattern" && "Digite um e-mail válido"}
                    {errors.email?.type === "wrongDomain" && <VStack w={"100%"}>
                        <Text
                            dangerouslySetInnerHTML={{ __html: `O correto não seria ${email.replace(wrongDomain, `<Text as="span" class="highlight">${correctDomain}</Text>`)}?` }}
                        />
                        <Button
                            variant={"solid"}
                            size={"xs"}
                            borderRadius={"full"}
                            fontFamily={"Roboto"}
                            colorScheme={"blue"}
                            onClick={() => {
                                setEmail(email.replace(wrongDomain, correctDomain))
                                clearErrors('email')
                            }}
                        >
                            Sim. Corrigir
                        </Button>
                    </VStack>}
                </FormErrorMessage>
            </FormControl>
            <InputGroup mt="1rem">
                <FormControl isInvalid={errors.city} mr={country === 'Brasil' ? '0.5rem' : '0'}>
                    <Input
                        name="city"
                        variant="filled"
                        colorScheme="gray"
                        placeholder="Cidade"
                        borderRadius="50"
                        borderColor="black"
                        onFocus={toogleErroMessage}
                        value={city}
                        {...register("city", {
                            required: true, minLength: 2, maxLength: 30, onChange: (e) => {
                                setCity(e.target.value)
                            }
                        })}
                    />
                    <FormErrorMessage pl="1rem">
                        {errors.city?.type === "required" && "Esse campo é obrigatório"}
                        {errors.city?.type === "minLength" && "Digite o nome da sua cidade"}
                        {errors.city?.type === "maxLength" && "Digite apenas o nome da sua cidade"}
                    </FormErrorMessage>
                </FormControl>
                {country === 'Brasil' && <FormControl w={'40%'}>
                    <Select
                        fontFamily='Courier'
                        name="state"
                        variant="filled"
                        colorScheme="gray"
                        placeholder="-UF-"
                        borderRadius="50"
                        borderColor="black"
                        onFocus={toogleErroMessage}
                        value={state}
                        {...register("state", {
                            onChange: (e) => {
                                setState(e.target.value)
                            }
                        })}
                    >
                        {(uf.map((item, index) => {
                            return <option key={index} value={item.sigla}>{item.sigla}</option>
                        }))}
                    </Select>
                </FormControl>}
            </InputGroup>
            <InputGroup mt="1rem">
                <FormControl>
                    <Select
                        name="country"
                        variant="filled"
                        colorScheme="gray"
                        placeholder="-Selecione seu país-"
                        borderRadius="50"
                        borderColor="black"
                        onFocus={toogleErroMessage}
                        value={country}
                        {...register("country", {
                            onChange: (e) => {
                                setCountry(e.target.value)
                            }
                        })}
                    >
                        {(countries.map((item) => {
                            return <option
                                key={item.names.portuguese}
                                value={item.names.portuguese}
                            >
                                {item.names.portuguese}
                            </option>
                        }))}
                    </Select>
                </FormControl>
            </InputGroup>
            <center>
                <Button
                    type="submit"
                    colorScheme="blue"
                    bgColor='blueBrand.500'
                    borderRadius="50"
                    mt="1rem"
                    w="140px"
                    h={"45px"}
                    fontSize={"18px"}
                    fontFamily={"button"}
                    textTransform={"uppercase"}
                    isLoading={isSubmitting}
                >
                    Assinar
                </Button>
            </center>

            <ReCAPTCHA
                sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}
                size="invisible"
                ref={reRef}
            />
        </form>

        <Collapse in={isOpen} animateOpacity>
            <Alert status="error" variant="top-accent" mt="0.5rem">
                <AlertIcon />
                <AlertTitle mr={2}>Erro!</AlertTitle>
                <AlertDescription>{apiReturnMessage}</AlertDescription>
            </Alert>
        </Collapse>
    </Box>)
}